





























import { Component, Prop, Vue } from 'vue-property-decorator'

// TODO: tests for `Subtask.vue`

@Component({
  components: {
    Deadline: () => import('@/components/UI/Deadline.vue'),
    UserInfoWindow:
      () => import('@/components/Chat/Instance/DOM/Components/UserInfoPopup/UserInfoWindow.vue'),
  },
})
export default class Subtask extends Vue {
  @Prop({ type: String, required: true }) readonly name!: string
  @Prop({ type: String, required: true }) readonly assigneeJid!: string
  @Prop({ type: String, required: true }) readonly assigneeName!: string
  @Prop({ type: String }) readonly statusTitle?: string
  @Prop({ type: String }) readonly deadline?: string
  @Prop({ type: Boolean }) readonly deadlineExpired?: string
  @Prop({ type: Boolean }) readonly isDone?: string
}
